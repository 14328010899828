import { Container, InputGroup } from 'react-bootstrap'
import Panel from '../global/panel'
import { useState, useEffect } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { setRefreshDataActionCreator } from '../../redux/actions/actions'
import { createSite } from '../../api/callApi'


const CreateSite = ({ data, usagePassword, setRefreshData, types }) => {
    const [currentDomain, setCurrentDomain] = useState("Choississez un domaine")
    const [currentType, setCurrentType] = useState("A")
    const [currentSubDomain, setCurrentSubDomain] = useState("")
    const [currentTarget, setCurrentTarget] = useState("")
    const [currentIp, setCurrentIp] = useState("")
    const [currentPort, setCurrentPort] = useState("")
    const [currentSsl, setcurrentSsl] = useState("true")
    const [siteToCreate, setSiteToCreate] = useState("")
    const [useApi, setUseApi] = useState(false)

    const createSiteProcess = () => {
        setSiteToCreate({ mainDomain: currentDomain, fieldType: currentType, subDomain: currentSubDomain, ovhTarget: currentTarget, proxyIp: currentIp, proxyPort: currentPort, ssl: currentSsl })
        setUseApi(true)
    }

    useEffect(() => {
        if (useApi && siteToCreate !== "") {
            createSite(usagePassword, siteToCreate, (d) => { console.log(d) }, (d) => { console.log(d) })
            setSiteToCreate("")
            setUseApi(false)
            setTimeout(() => {
                setRefreshData(true)
            }, 1000)
        }
    }, [useApi, siteToCreate, usagePassword, setRefreshData])

    return (
        <Panel subPanel={"medium"} legend={"Créer site"} content={
            <Container>
                <Row>
                    <Col xs={12} md={3}>
                        <Form.Control className="mb-2" onChange={e => setCurrentDomain(e.target.value)} as="select" custom>
                            <option>Choississez un domaine</option>
                            {
                                data.map(domainData => {
                                    return (
                                        <option>{domainData.name}</option>
                                    )
                                })
                            }
                        </Form.Control>
                    </Col >
                    <Col xs={12} md={3}>
                        <Form.Control className="mb-2" onChange={e => setCurrentType(e.target.value)} as="select" custom>
                            {
                                types.map(type => {
                                    return (
                                        <option>{type}</option>
                                    )
                                })
                            }
                        </Form.Control>
                    </Col >
                    <Col xs={12} md={3}>
                        <Form.Control className="mb-2" onChange={e => setCurrentSubDomain(e.target.value)} placeHolder={"Sous domaine..."} type="text"></Form.Control>
                    </Col>
                    <Col xs={12} md={3}>
                        <Form.Control className="mb-2" onChange={e => setCurrentTarget(e.target.value)} placeHolder={"Cible..."} type="text"></Form.Control>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <Form.Control className="mb-2" onChange={e => setCurrentIp(e.target.value)} placeHolder={"Ip de redirection"} type="text"></Form.Control>
                    </Col >
                    <Col xs={12} md={4}>
                        <Form.Control className="mb-2" onChange={e => setCurrentPort(e.target.value)} placeHolder={"Port de redirection"} type="number"></Form.Control>
                    </Col >
                    <Col xs={12} md={4}>
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>SSL</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control onChange={e => setcurrentSsl(e.target.value)} as="select" custom>
                                <option>true</option>
                                <option>false</option>
                            </Form.Control>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="d-flex justify-content-center">
                        <Button variant="outline-success" onClick={createSiteProcess} >❎ Créer</Button>
                    </Col>
                </Row>
            </Container>
        }></Panel>
    )
}


const mapStateToProps = state => ({
    usagePassword: state.usagePassword,
    data: state.data,
    types: state.types
})

const mapDispatchToProps = (dispatch) => ({
    setRefreshData: (needRefresh) => dispatch(setRefreshDataActionCreator(needRefresh))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateSite)