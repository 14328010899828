import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import crypto from 'crypto'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import { validateUsagePassword } from "../../api/callApi";
import { connect } from 'react-redux'
import { setUsagePasswordActionCreator, setRefreshDataActionCreator, setLocationActionCreator } from '../../redux/actions/actions'



const Menu = ({ setUsagePassword, setRefreshData, location, setLocation }) => {

    const [password, setPassword] = useState("")
    const [useApi, setUseApi] = useState(false)
    const [checkOk, setCheckOk] = useState(false)
    const [isConnected, setIsConnected] = useState(false)
    const listOfButtons = [
        { link: "/dns", text: "Dns" },
        { link: "/site", text: "Site" }
    ]

    const useUpdateLocation = (setLocation) => {
        let loc = useLocation();
        useEffect(() => {
            setLocation(loc);
        }, [loc, setLocation]);
    }

    const onChangeUsagePassword = e => {
        setPassword(crypto.createHash('sha256').update(e.target.value).digest('hex').toUpperCase())
    }

    const onKeyPress = e => {
        if (e.type === "keypress" && e.key === "Enter") {
            setUseApi(true)
        }
    }

    useEffect(() => {
        if (useApi) {
            validateUsagePassword(password, setCheckOk)
            setUseApi(false)
        }
    }, [useApi, password])

    useEffect(() => {
        if (checkOk) {
            setUsagePassword(password)
            setRefreshData(true)
            setIsConnected(true)
            setCheckOk(false)
        }

    }, [checkOk, password, setUsagePassword, setRefreshData])

    useUpdateLocation(setLocation)

    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Navbar.Brand>Proxy Manager</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    {listOfButtons.map((btn, index) => {
                        return (
                            <Nav.Link className="d-flex justify-content-center">
                                <Link to={btn.link}>
                                    <Button active={location.pathname === btn.link ? true : false} variant="outline-info" >{btn.text}</Button>
                                </Link>
                            </Nav.Link>
                        )
                    })}
                </Nav>
                <Form.Row>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <Button onClick={e => setUseApi(true)} variant="outline-secondary" disabled={isConnected} >{isConnected ? "✅" : "🔒"}</Button>
                        </InputGroup.Prepend>
                        <FormControl disabled={isConnected} onChange={onChangeUsagePassword} onKeyPress={e => onKeyPress(e)} type="password" placeholder="Mdp d'utilisation" aria-label="Mdp d'utilisation" aria-describedby="basic-addon1" />
                    </InputGroup>
                </Form.Row>
            </Navbar.Collapse>
        </Navbar>
    )
}

const mapStateToProps = state => ({
    location: state.location
})

const mapDispatchToProps = (dispatch) => ({
    setUsagePassword: (password) => dispatch(setUsagePasswordActionCreator(password)),
    setRefreshData: (refresh) => dispatch(setRefreshDataActionCreator(refresh)),
    setLocation: (location) => dispatch(setLocationActionCreator(location))
})

export default connect(mapStateToProps, mapDispatchToProps)(Menu)