import Panel from '../global/panel'
import ViewDns from './viewDns'
import CreateDns from './createDns'
import { Container } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'

const Dns = () => {
    return (
        <Panel legend={"DNS"} content={
            <Container>
                <br></br>
                <Row className="justify-content-center">
                    <Col>
                        <ViewDns></ViewDns>
                    </Col>
                </Row>
                <br></br>
                <Row className="justify-content-center">
                    <Col>
                        <CreateDns></CreateDns>
                    </Col>
                </Row>
                <br></br>
            </Container>
        }></Panel>
    )
}


export default Dns