import { Container, Table } from 'react-bootstrap'
import Panel from '../global/panel'
import { useState } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { setRefreshDataActionCreator } from '../../redux/actions/actions'
import EntryDNS from './entryDNS'

const ViewDns = ({ data, types, setRefreshData }) => {
    const [currentDomain, setCurrentDomain] = useState("Tous les domaines")
    const [search, setSearch] = useState("")
    const [currentType, setCurrentType] = useState("Tous les types")

    const displayRow = () => {
        return data
            .filter(domainData => currentDomain === "Tous les domaines" || domainData.name === currentDomain)
            .map(domainData => domainData.entries
                .filter(entry => entry.subDomain.includes(search) && (currentType === "Tous les types" || entry.fieldType === currentType))
                .map(entry => {
                    return (
                        <EntryDNS entry={entry}></EntryDNS>
                    )
                }))
    }

    return (
        <Panel subPanel={"medium"} legend={"Vue Dns"} content={
            <Container>
                <Container>
                    <Row>
                        <Col xs={12} md={3}>
                            <Form.Control className="mb-2" onChange={e => setCurrentDomain(e.target.value)} as="select" custom>
                                <option>Tous les domaines</option>
                                {
                                    data.map(domainData => {
                                        return (
                                            <option>{domainData.name}</option>
                                        )
                                    })
                                }
                            </Form.Control>
                        </Col >
                        <Col xs={12} md={3}>
                            <Form.Control className="mb-2" onChange={e => setCurrentType(e.target.value)} as="select" custom>
                                <option>Tous les types</option>
                                {
                                    types.map(type => {
                                        return (
                                            <option>{type}</option>
                                        )
                                    })

                                }
                            </Form.Control>
                        </Col >
                        <Col xs={12} md={3}>
                            <Form.Control className="mb-2" onChange={e => setSearch(e.target.value)} placeholder="Sous domaine..." type="text"></Form.Control>
                        </Col>
                        <Col xs={12} md={3} className="d-flex justify-content-center">
                            <Button className="mb-2" variant="outline-info" onClick={e => setRefreshData(true)} >🔄 Actualiser</Button>
                        </Col>
                    </Row>
                </Container>
                <Container className="scrollable">

                    <Table responsive striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Type</th>
                                <th>Sous domaine</th>
                                <th>Domaine</th>
                                <th>Cible</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayRow()}
                        </tbody>
                    </Table>
                </Container>
            </Container>
        }></Panel>
    )
}



const mapStateToProps = state => ({
    usagePassword: state.usagePassword,
    data: state.data,
    types: state.types
})

const mapDispatchToProps = (dispatch) => ({
    setRefreshData: (needRefresh) => dispatch(setRefreshDataActionCreator(needRefresh))
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewDns)