import { SET_REFRESH_DATA, SET_DATA, SET_USAGE_PASSWORD, SET_LOCATION } from "../actions/actions"

const defaultState = {
    usagePassword: "",
    data: [],
    refreshData: false,
    types: ["A", "AAAA", "CAA", "CNAME", "DKIM", "DMARC", "LOC", "MX", "NAPTR", "NS", "PTR", "SPF", "SRV", "SSHFP", "TLSA", "TXT"],
    location:""
}

export const globalReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_REFRESH_DATA:
            return {
                ...state,
                refreshData: action.payload
            }
        case SET_USAGE_PASSWORD:
            return {
                ...state,
                usagePassword: action.payload
            }
        case SET_DATA:
            return {
                ...state,
                data: action.payload
            }
        case SET_LOCATION:
            return {
                ...state,
                location: action.payload
            }
        default:
            return state
    }
}