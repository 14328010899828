import { Container, InputGroup } from 'react-bootstrap'
import Panel from '../global/panel'
import { useState, useEffect } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { setRefreshDataActionCreator } from '../../redux/actions/actions'
import { deleteSite } from '../../api/callApi'


const DeleteSite = ({ data, usagePassword, setRefreshData, types }) => {
    const [currentDomain, setCurrentDomain] = useState("Choississez un domaine")
    const [currentType, setCurrentType] = useState("A")
    const [currentSubDomain, setCurrentSubDomain] = useState("")
    const [currentSsl, setCurrentSsl] = useState("true")
    const [siteToDelete, setSiteToDelete] = useState("")
    const [useApi, setUseApi] = useState(false)

    const deleteSiteProcess = () => {
        setSiteToDelete({ mainDomain: currentDomain, fieldType: currentType, subDomain: currentSubDomain, ssl: currentSsl })
        setUseApi(true)
    }

    useEffect(() => {
        if (useApi && siteToDelete !== "") {
            deleteSite(usagePassword, siteToDelete, (d) => { console.log(d) }, (d) => { console.log(d) })
            setSiteToDelete("")
            setUseApi(false)
            setTimeout(() => {
                setRefreshData(true)
            }, 1000)
        }
    }, [useApi, siteToDelete, usagePassword, setRefreshData])

    return (
        <Panel subPanel={"medium"} legend={"Supprimer site"} content={
            <Container>
                <Row>
                    <Col xs={12} md={3}>
                        <Form.Control className="mb-2" onChange={e => setCurrentDomain(e.target.value)} as="select" custom>
                            <option>Choississez un domaine</option>
                            {
                                data.map(domainData => {
                                    return (
                                        <option>{domainData.name}</option>
                                    )
                                })
                            }
                        </Form.Control>
                    </Col >
                    <Col xs={12} md={3}>
                        <Form.Control className="mb-2" onChange={e => setCurrentType(e.target.value)} as="select" custom>
                            {
                                types.map(type => {
                                    return (
                                        <option>{type}</option>
                                    )
                                })

                            }
                        </Form.Control>
                    </Col >
                    <Col xs={12} md={3}>
                        <Form.Control className="mb-2" onChange={e => setCurrentSubDomain(e.target.value)} placeHolder={"Sous domaine..."} type="text"></Form.Control>
                    </Col>
                    <Col xs={12} md={3}>
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>SSL</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control onChange={e => setCurrentSsl(e.target.value)} as="select" custom>
                                <option>true</option>
                                <option>false</option>
                            </Form.Control>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="d-flex justify-content-center">
                        <Button variant="outline-danger" onClick={deleteSiteProcess} >❌ Supprimer</Button>
                    </Col>
                </Row>
            </Container>
        }></Panel>
    )
}


const mapStateToProps = state => ({
    usagePassword: state.usagePassword,
    data: state.data,
    types: state.types
})

const mapDispatchToProps = (dispatch) => ({
    setRefreshData: (needRefresh) => dispatch(setRefreshDataActionCreator(needRefresh))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSite)