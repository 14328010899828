import Panel from '../global/panel'
import { Container, Row, Col } from 'react-bootstrap'
import CreateSite from './createSite'
import DeleteSite from './deleteSite'

const Site = () => {
    return (
        <Panel legend={"Site"} content={
            <Container>
                <br></br>
                <Row className="justify-content-center">
                    <Col>
                        <CreateSite></CreateSite>
                    </Col>
                </Row>
                <br></br>
                <Row className="justify-content-center">
                    <Col>
                        <DeleteSite></DeleteSite>
                    </Col>
                </Row>
                <br></br>
            </Container>
        }></Panel>
    )
}


export default Site