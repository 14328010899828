const Panel = ({ legend, content, isSelected, subPanel }) => {
    const html = []
    if (legend) {
        if (subPanel === "small") {
            html.push(<h6 className={isSelected ? "panel-legend panel-legend-selected" : "panel-legend"}>{legend}</h6>)
        }
        else if (subPanel === "medium") {
            html.push(<h3 className={isSelected ? "panel-legend panel-legend-selected" : "panel-legend"}>{legend}</h3>)
        }
        else {
            html.push(<h1 className={isSelected ? "panel-legend panel-legend-selected" : "panel-legend"}>{legend}</h1>)
        }
    }
    html.push(<div className={isSelected ? "panel panel-selected" : "panel"}>{content}</div>)
    return (<div>{html}</div>)
}

export default Panel