import Container from 'react-bootstrap/Container';
import Menu from './menu';
import Site from '../site';
import Dns from '../dns';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Error404 from './error404';
import { useEffect } from 'react';
import { getAllData } from '../../api/callApi';
import { connect } from 'react-redux'
import { setDataActionCreator, setRefreshDataActionCreator } from '../../redux/actions/actions'


const App = ({ usagePassword, refreshData, setData, setRefreshData }) => {

    useEffect(() => {
        if (refreshData && usagePassword !== "") {
            getAllData(usagePassword, setData)
            setRefreshData(false)
        }
    }, [refreshData, setRefreshData, usagePassword, setData])

    return (
        <div className="app">
            <BrowserRouter>
                <Menu></Menu>
                <Container fluid>
                    <br></br>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/dns" />
                        </Route>
                        <Route path="/dns">
                            <Dns></Dns>
                        </Route>
                        <Route path="/site">
                            <Site></Site>
                        </Route>
                        <Route path="*">
                            <Error404></Error404>
                        </Route>
                    </Switch>
                </Container>
            </BrowserRouter>
        </div>
    );
}

const mapStateToProps = state => ({
    usagePassword: state.usagePassword,
    refreshData: state.refreshData
})

const mapDispatchToProps = (dispatch) => ({
    setData: (data) => dispatch(setDataActionCreator(data)),
    setRefreshData: (refresh) => dispatch(setRefreshDataActionCreator(refresh))
})

export default connect(mapStateToProps, mapDispatchToProps)(App);