import Axios from 'axios'
import env from '../env.js'

const buildUrl = (url, usagePassword) => {
    return `${env.API_URL}${url}?usagePassword=${usagePassword}`
}

export const validateUsagePassword = async (usagePassword, setCheckOk) => {
    try {
        const response = await Axios.get(buildUrl("/api/validate", usagePassword))
        if (response.status === 204) {
            setCheckOk(true)
        }
    }
    catch (e) {
        setCheckOk(false)
    }
}

export const getAllData = async(usagePassword, setterData) => {
    console.log("call api")
    try {
        const response = await Axios.get(buildUrl("/dns/domains", usagePassword))
        if (response.status === 200) {
            const data = []
            const types = ["A","AAAA","CAA","CNAME","DKIM","DMARC","LOC","MX","NAPTR","NS","PTR","SPF","SRV","SSHFP","TLSA","TXT"]
            const domains = response.data
            for(let i = 0; i < domains.length; i++){
                let domainData = {name:domains[i], entries: []}
                for(let j = 0; j < types.length; j++){
                    const response2 = await Axios.get(buildUrl(`/dns/${domains[i]}/${types[j]}`, usagePassword))
                    if(response2.status === 200){
                        domainData.entries = [...domainData.entries, ...response2.data]
                    }
                }
                data.push(domainData)
            }
            setterData(data)
        }
    }
    catch (e) {
        
    }
}

export const getDomains = async (usagePassword, setterData, setterInfo) => {
    try {
        const response = await Axios.get(buildUrl("/dns/domains", usagePassword))
        if (response.status === 200) {
            setterData(response.data)
        }
    }
    catch (e) {
        if (e?.response?.data) {
            setterInfo(e.response.data)
        }
        else {
            setterInfo(e.message)
        }
    }
}

export const getDNSEntries = async (usagePassword, domain, fieldType, setterData, setterInfo) => {
    try {
        const response = await Axios.get(buildUrl(`/dns/${domain}/${fieldType}`, usagePassword))
        if (response.status === 200) {
            setterData(response.data)
        }
    }
    catch (e) {
        if (e?.response?.data) {
            setterInfo(e.response.data)
        }
        else {
            setterInfo(e.message)
        }
    }
}

export const createSite = async (usagePassword, site, setterData, setterInfo) => {
    try {
        const response = await Axios.post(buildUrl(`/site/create`, usagePassword), site)
        if (response.status === 200) {
            setterData(response.data)
        }
    }
    catch (e) {
        if (e?.response?.data) {
            setterInfo(e.response.data)
        }
        else {
            setterInfo(e.message)
        }
    }
}

export const createDNSEntry = async (usagePassword, entry, setterData, setterInfo) => {
    try {
        const response = await Axios.post(buildUrl(`/dns/create/entry`, usagePassword), entry)
        if (response.status === 200) {
            setterData(response.data)
        }
    }
    catch (e) {
        if (e?.response?.data) {
            setterInfo(e.response.data)
        }
        else {
            setterInfo(e.message)
        }
    }
}


export const refreshDNS = async (usagePassword, domain, setterData, setterInfo) => {
    try {
        const response = await Axios.post(buildUrl(`/dns/${domain}/refresh`, usagePassword))
        if (response.status === 200) {
            setterData(response.data)
        }
    }
    catch (e) {
        if (e?.response?.data) {
            setterInfo(e.response.data)
        }
        else {
            setterInfo(e.message)
        }
    }
}

export const deleteSite = async (usagePassword, site, setterData, setterInfo) => {
    try {
        const response = await Axios.delete(buildUrl(`/site/delete`, usagePassword), { data:site})
        if (response.status === 200) {
            setterData(response.data)
        }
    }
    catch (e) {
        if (e?.response?.data) {
            setterInfo(e.response.data)
        }
        else {
            setterInfo(e.message)
        }
    }
}

export const deleteDNSEntry = async (usagePassword, entry, setterData, setterInfo) => {
    try {
        console.log(entry)
        const response = await Axios.delete(buildUrl(`/dns/delete/entry`, usagePassword), { data:entry})
        if (response.status === 200) {
            setterData(response.data)
        }
    }
    catch (e) {
        if (e?.response?.data) {
            setterInfo(e.response.data)
        }
        else {
            setterInfo(e.message)
        }
    }
}
