import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './components/global';
import {Provider} from 'react-redux'
import {createStore} from 'redux'
import { globalReducer } from './redux/reducers/globalReducer';

const store = createStore(globalReducer)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)