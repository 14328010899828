export const SET_REFRESH_DATA = 'REFRESH_DATA'
export const SET_USAGE_PASSWORD = 'SET_USAGE_PASSWORD'
export const SET_DATA = 'SET_DATA'
export const SET_LOCATION = 'SET_LOCATION'

export const setRefreshDataActionCreator = needCallApi => ({
    type: SET_REFRESH_DATA,
    payload: needCallApi
})

export const setUsagePasswordActionCreator = usagePassword => ({
    type: SET_USAGE_PASSWORD,
    payload: usagePassword
})

export const setDataActionCreator = data => ({
    type: SET_DATA,
    payload: data
})

export const setLocationActionCreator = location => ({
    type: SET_LOCATION,
    payload: location
})