import { Container } from 'react-bootstrap'
import Panel from '../global/panel'
import { useState, useEffect } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { setRefreshDataActionCreator } from '../../redux/actions/actions'
import { createDNSEntry } from '../../api/callApi'


const CreateDns = ({ data, usagePassword, setRefreshData, types }) => {
    const [currentDomain, setCurrentDomain] = useState("Choississez un domaine")
    const [currentType, setCurrentType] = useState("A")
    const [currentSubDomain, setCurrentSubDomain] = useState("")
    const [currentTarget, setCurrentTarget] = useState("")
    const [entryToCreate, setEntryToCreate] = useState("")
    const [useApiCreateEntry, setUseApiCreateEntry] = useState(false)

    const createEntry = () => {
        console.log({ mainDomain: currentDomain, fieldType: currentType, subDomain: currentSubDomain, ovhTarget: currentTarget })
        setEntryToCreate({ mainDomain: currentDomain, fieldType: currentType, subDomain: currentSubDomain, ovhTarget: currentTarget })
        setUseApiCreateEntry(true)
    }
    useEffect(() => {
        if (useApiCreateEntry && entryToCreate !== "") {
            createDNSEntry(usagePassword, entryToCreate, (d) => { console.log(d) }, (d) => { console.log(d) })
            setEntryToCreate("")
            setUseApiCreateEntry(false)
            setTimeout(() => {
                setRefreshData(true)
            }, 1000)
        }
    }, [useApiCreateEntry, entryToCreate, usagePassword, setRefreshData])

    return (
        <Panel subPanel={"medium"} legend={"Créer entrée"} content={
            <Container>
                <Row>
                    <Col xs={12} md={3}>
                        <Form.Control className="mb-2" onChange={e => setCurrentDomain(e.target.value)} as="select" custom>
                            <option>Choississez un domaine</option>
                            {
                                data.map(domainData => {
                                    return (
                                        <option>{domainData.name}</option>
                                    )
                                })
                            }
                        </Form.Control>
                    </Col >
                    <Col xs={12} md={3}>
                        <Form.Control className="mb-2" onChange={e => setCurrentType(e.target.value)} as="select" custom>
                            {
                                types.map(type => {
                                    return (
                                        <option>{type}</option>
                                    )
                                })

                            }
                        </Form.Control>
                    </Col >
                    <Col xs={12} md={3}>
                        <Form.Control className="mb-2" onChange={e => setCurrentSubDomain(e.target.value)} placeHolder={"Sous domaine..."} type="text"></Form.Control>
                    </Col>
                    <Col xs={12} md={3}>
                        <Form.Control className="mb-2" onChange={e => setCurrentTarget(e.target.value)} placeHolder={"Cible..."} type="text"></Form.Control>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="d-flex justify-content-center">
                        <Button variant="outline-success" onClick={createEntry} >❎ Créer</Button>
                    </Col>
                </Row>
            </Container>
        }></Panel>
    )
}


const mapStateToProps = state => ({
    usagePassword: state.usagePassword,
    data: state.data,
    types: state.types
})

const mapDispatchToProps = (dispatch) => ({
    setRefreshData: (needRefresh) => dispatch(setRefreshDataActionCreator(needRefresh))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateDns)