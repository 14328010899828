import Panel from './panel'
import { Container, Row, Col, Card } from 'react-bootstrap'

const Error404 = () => {
    return (
        <Row className="justify-content-center">
            <Col xs={8} md={6} lg={4}>
                <Panel subPanel={"medium"} legend={"Error 404"} content={
                    <Container >
                        <Card>
                            <Card.Img variant="top" src="https://media.giphy.com/media/wvQIqJyNBOCjK/giphy.gif" />
                        </Card>
                    </Container>
                }>
                </Panel>
            </Col>
        </Row>
    )
}


export default Error404