import { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { setRefreshDataActionCreator } from '../../redux/actions/actions'
import { deleteDNSEntry } from '../../api/callApi'


const EntryDNS = ({ entry, usagePassword, setRefreshData }) => {
    const [useApi, setUseApi] = useState(false)


    useEffect(() => {
        if (useApi && entry !== "") {
            deleteDNSEntry(usagePassword, { mainDomain: entry.zone, entryId: entry.id.toString() }, (d) => { console.log(d) }, (d) => { console.log(d) })
            setRefreshData(true)
            setUseApi(false)
        }
    }, [entry, useApi, usagePassword, setRefreshData])

    return (
        <tr>
            <td>{entry.id}</td>
            <td>{entry.fieldType}</td>
            <td>{entry.subDomain}</td>
            <td>{entry.zone}</td>
            <td>{entry.target}</td>
            <td><Button variant="outline-danger" onClick={e => setUseApi(true)} >❌ Supprimer</Button></td>
        </tr>
    )
}


const mapStateToProps = state => ({
    usagePassword: state.usagePassword,
    data: state.data,
    types: state.types
})

const mapDispatchToProps = (dispatch) => ({
    setRefreshData: (needRefresh) => dispatch(setRefreshDataActionCreator(needRefresh))
})

export default connect(mapStateToProps, mapDispatchToProps)(EntryDNS)